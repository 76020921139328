import log from 'loglevel';

class Router {

  constructor() {
    //window.addEventListener('popstate', e => this.popStateHandler(e));
  }

  /*popStateHandler(e) {
    if (this.currentPathname === e.target.location.pathname,  e.target.location.pathname) {
      this.goto(e.target.location.pathname);
    }
    window.history.
  }*/
  
  /**
   * 
   * @param {string} path 
   */
  goto(path) {
    // loop through all but the last stack item
    log.debug('goto');
    for (let i = 0; i < window.state.stack.length - 1; i++) {
      if (typeof window.state.stack[i] === 'function') {
        log.debug('destruct (f)');
        window.state.stack[i]('destruct');
      } else {
        log.debug('destruct');
        window.state.stack[i].destruct();
      }
    }
    this.pushHistory({}, path)
    const rootView = window.state.stack[window.state.stack.length - 1];
    if (window.state.url) window.state.url.loaded = false;
    if (window.state.stack[0]) {
      window.state.stack = [rootView];
      if (typeof window.state.stack[0] === 'function') {
        window.state.stack[0]();
      } else {
        log.debug('stack', window.state.stack)
        window.state.stack[0].show();
      }
    }
  }

  goBack(settings = undefined) {
    log.debug('Router: go back')
    if (typeof window.state.stack[0] === 'function') {
      window.state.stack[0]('destruct');
    } else {
      window.state.stack[0].destruct();
    }

		// unshift this view from stack
		window.state.stack.shift();
    log.debug('View.close, stack shift', window.state.stack, this);

    // show previous view
    if (typeof window.state.stack[0] === 'function') {
      window.state.stack[0](settings);
    } else {
      window.state.stack[0].show(settings);
    }
  }

  pushHistory(data, url) {
    //browser back button currently disabled/not implemented
    //console.log('popstate pushhistory', url)
    //this.currentPathname = url;
    window.history.pushState(data, '', url);
  }
}

const router = new Router();
log.debug('CREATE NEW ROUTER');
export default router;