import log from 'loglevel';
if (window.location.hostname === 'localhost' || window.location.hostname.substr(0, 7) === '192.168') {
  log.setLevel('trace');
} else {
  log.setLevel('info');
}

import './settings';
import 'regenerator-runtime/runtime.js';
import logger from './core/Logger';
import firebase from './core/firebase';
import User from './core/User';
import navbar from './core/navbar';

const checkForUpdates = () => {
  if (firebase.isAmi) {
    let versionNumbers = window.state.version.split('.');
    let versionNumber =
      parseInt(versionNumbers[0]) * 1000000 + parseInt(versionNumbers[1]) * 1000 + parseInt(versionNumbers[2]);
    firebase.db
      .collection('info')
      .doc('general')
      .get()
      .then((doc) => {
        if (doc.exists) {
          const data = doc.data();
          console.log('versionCheck', data.version, versionNumber, data.version > versionNumber);
          if (data.version > versionNumber) {
            document.getElementById('versionNotice').style.display = 'block';
          }
        }
      });
  }
};

document.querySelector('#signin_anonymous').addEventListener('click', (e) => {
  firebase.authAnonymous();
});

if (window.location.hostname !== 'localhost' && window.location.hostname.substr(0, 7) !== '192.168') {
  window.onerror = async (ev, src, line, col, err) => {
    log.debug('window.onerror');
    logger.exception(ev, err);
  };

  window.addEventListener('unhandledrejection', async (event) => {
    log.debug('unhandledrejection');
    // the event object has two special properties:
    //log.error(event.promise); // [object Promise] - the promise that generated the error
    //log.error(event.reason); // Error: Whoops! - the unhandled error object
    const message = event.reason;
    logger.exception(message, event.reason);
  });
}

if (firebase.isAmi) {
  window.addEventListener('load', () => navbar.show());
  document.querySelector('.teori-ami').style.display = 'block';
  firebase.auth();
  checkForUpdates();
} else if (firebase.isPublic) {
  document.querySelector('.teori-public').style.display = 'block';
  firebase.authAnonymous();
} else if (firebase.isTuneLearner) {
  document.querySelector('.teori-tunelearner').style.display = 'block';
  firebase.authAnonymous();
}

async function getComponent() {
  const { default: _ } = await import(/* webpackChunkName: "app" */ './app');
  log.debug('currentUser', User.getCurrentUser());
  if (User.getCurrentUser()) {
    _();
  } else {
    firebase.onSignIn(() => {
      log.debug(_);
      _();
    });
  }
}

const app = getComponent();
